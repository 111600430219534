import React from 'react'
import './NotFound.css'
const NotFound = ({history})=>{
    return (
        <div className = 'nf_wrapper'>
            <h1>Oops!!! This Page Does Not Exist</h1>
        </div>
    )
}

export default NotFound